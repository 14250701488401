import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  round,
  uniq,
  uniqBy,
  toUpper,
} from 'lodash'

import { useAuthStore } from '@shared/store/auth'
import { useSpinnerStore } from '@shared/store/spinner'
import { useModalsStore } from '@shared/store/modals'
import * as mapbox from '@shared/http/mapbox'
import useFormFields from '@shared/hooks/form/formFields'

export default function useUserGeolocSetting(props, options = {}) {
  const authStore = useAuthStore()
  const spinnerStore = useSpinnerStore()
  const modalsStore = useModalsStore()

  const { t } = useI18n()
  const formFieldsHook = useFormFields(props)
  const { nameToUse } = formFieldsHook

  // Retrieve form instance, from options, or from hook
  const form = options.form ? ref(options.form) : formFieldsHook.form

  // ---------- KIND ----------

  // Build available kinds options
  const kindsOptions = computed(() => {
    // "Around me" is the first option
    const values = ['around_me']

    // If user is staying at hotel
    if (authStore.user.relationships.staying_hotel) {
      values.push('staying_hotel')
    }

    // Always "Customized" as the last option
    values.push('customized')

    // Format options
    return values.map((value) => ({
      value,
      label: t(`enums.UserGeolocSetting.KindEnum.${toUpper(value)}`),
    }))
  })

  const selectedKind = computed(() => (
    form.value.values.kind
  ))

  // ---------- RADIUS ----------

  const distanceCoeff = 1.609

  const measurementSystemValue = computed(() => (
    form.value?.values?.measurement_system
  ))

  const limitToARadiusValue = computed(() => (
    form.value?.values?.limit_to_a_radius
  ))

  const radiusLabelToUse = computed(() => (
    form.value?.values?.kind === 'customized'
      ? t('form.labels.geoloc_setting.limit_to_a_radius_from_center')
      : t('form.labels.geoloc_setting.limit_to_a_radius')
  ))

  // Increase max radius for metric measurement
  const maxRadius = computed(() => (
    measurementSystemValue.value === 'metric'
      ? 50
      : 50 / distanceCoeff
  ))

  watch(measurementSystemValue, (newValue, oldValue) => {
    const radiusValue = form.value?.values?.[nameToUse('radius')]
    let newRadiusValue = null

    // Convert radius value from/to metric/imperial measurement
    if (radiusValue) {
      if (newValue === 'imperial' && oldValue === 'metric') {
        newRadiusValue = radiusValue / distanceCoeff
      } else if (newValue === 'metric' && oldValue === 'imperial') {
        newRadiusValue = radiusValue * distanceCoeff
      }

      if (newRadiusValue) {
        newRadiusValue = round(newRadiusValue, 2)

        form.value.setFieldValue(
          nameToUse('radius'),
          newRadiusValue,
        )
      }
    }
  })

  function formatSliderTooltips(value) {
    const suffix = measurementSystemValue.value === 'metric'
      ? t('common.units.km')
      : t('common.units.mi')

    return `${round(value, 0)} ${suffix}`
  }

  // ---------- FILTER OPTIONS ----------

  const filterOptions = ref([])
  const initialFilterSearch = ref(true)

  function handleGeolocFilterSearch(searchKeywords) {
    return new Promise((resolve) => {
      filterOptions.value = []

      if (initialFilterSearch.value) {
        initialFilterSearch.value = false

        if (props.resource?.attributes?.kind === 'customized') {
          // Provide an option with the resource's attribute value
          filterOptions.value.push({
            label: props.resource.attributes.filter,
            value: props.resource.attributes.filter,
          })
        }
      }

      if (searchKeywords?.length > 0) {
        mapbox.geocoding(searchKeywords, {
          types: [
            'country',
            'region',
            'postcode',
            'district',
            'place',
            'locality',
            'neighborhood',
            'address',
            'poi',
          ].join(','),
          language: authStore.user.attributes.locale,
        })
          .then((r) => {
            const apiOptions = r.data.features
              ?.filter((place) => (
                // Check if name, latitude & longitude
                // are presents
                place.place_name
                  && place.text
                  && place.center?.[0]
                  && place.center?.[1]
              ))
              ?.map((place) => {
                // Build option's label
                let label = ''

                // Specify address in the label when available
                if (place.place_type.includes('address') && place.address) {
                  label = `${place.address} ${place.text}`
                } else if (place.place_type.includes('poi') && place.properties?.address) {
                  label = `${place.text}, ${place.properties.address}`
                } else {
                  label = place.text
                }

                let icon

                if (place.place_type.includes('country')) {
                  icon = 'globe'
                } else if (place.place_type.includes('region')) {
                  icon = 'map'
                } else if (place.place_type.includes('district')) {
                  icon = 'map'
                } else if (place.place_type.includes('postcode')) {
                  icon = 'map-marked-alt'
                } else if (place.place_type.includes('place')) {
                  icon = 'map-marked-alt'
                } else if (place.place_type.includes('neighborhood')) {
                  icon = 'map-signs'
                } else if (place.place_type.includes('locality')) {
                  icon = 'map-signs'
                }

                return {
                  value: place.place_name,
                  label,
                  subLabel: uniq(place.context?.map((c) => c.text)).join(', '),
                  context: place.context,
                  coords: {
                    latitude: place.center[1],
                    longitude: place.center[0],
                  },
                  icon,
                }
              })

            filterOptions.value = filterOptions.value.concat(apiOptions)
          })
          .finally(() => {
            resolve(uniqBy(filterOptions.value, 'value'))
          })
      } else {
        resolve(uniqBy(filterOptions.value, 'value'))
      }
    })
  }

  function handleKindChange(value) {
    if (value === 'around_me') {
      fillCoordsWithCurrentPosition()
    }
  }

  function fillCoordsWithCurrentPosition() {
    return new Promise((resolve, reject) => {
      spinnerStore.enable()

      // Try to get position
      navigator.geolocation.getCurrentPosition(
        // Success : navigator allowed to get position
        (position) => {
          // Adapt form fields
          form.value.setFieldValue(
            nameToUse('latitude'),
            position.coords.latitude,
          )

          form.value.setFieldValue(
            nameToUse('longitude'),
            position.coords.longitude,
          )

          spinnerStore.disable()
          resolve()
        },
        // Error
        (e) => {
          // Show "geoloc failed" modal
          modalsStore.show('geoloc_failed')

          // Set "geoloc failed" retry callback
          modalsStore.setProperties({
            name: 'geoloc_failed',
            retry_callback: fillCoordsWithCurrentPosition,
            error_code: e.code,
          })

          spinnerStore.disable()

          reject(e)
        },
        {
          timeout: 20000,
        },
      )
    })
  }

  function handleGeolocFilterChange(value) {
    // Retrieve selected option
    const option = filterOptions.value.find((filter) => (
      filter.value === value
    ))

    if (option?.coords) {
      // Apply new latitude and longitude
      // when a new filter is selected
      form.value.setFieldValue(
        nameToUse('latitude'),
        option.coords.latitude,
      )

      form.value.setFieldValue(
        nameToUse('longitude'),
        option.coords.longitude,
      )

      if (option?.context) {
        // Apply country code if available
        const context_country = option.context.find(
          (context) => context.id.startsWith('country'),
        )

        if (context_country?.short_code) {
          form.value.setFieldValue(
            'country_code',
            context_country.short_code.toUpperCase(),
          )
        }
      } else {
        // Reset country code if not available
        form.value.setFieldValue(
          'country_code',
          null,
        )
      }
    }
  }

  return {
    // Variables
    distanceCoeff,

    // Computed
    limitToARadiusValue,
    radiusLabelToUse,
    maxRadius,
    selectedKind,
    kindsOptions,

    // Functions
    formatSliderTooltips,
    handleGeolocFilterSearch,
    handleKindChange,
    handleGeolocFilterChange,
    fillCoordsWithCurrentPosition,
  }
}
